<form [formGroup]="pursuitForm"
      novalidate
      class="edit-pursuit-form edit-form">
    <ion-card>
        <ion-card-header>Pursuit details</ion-card-header>
        <ion-card-content>
            <div data-automation="pursuitForm-name">
                <ion-item>
                    <ion-label stacked
                            required>Pursuit name</ion-label>
                    <ion-input formControlName="name"
                            required></ion-input>
                </ion-item>
                <div class="field-error"
                    *ngIf="pursuitForm.controls['name'].dirty &&
                    pursuitForm.controls['name'].errors &&
                    pursuitForm.controls['name'].errors['required']">
                    A name is required.
                </div>
                <div class="field-error"
                    *ngIf="pursuitForm.controls['name'].dirty &&
                            pursuitForm.controls['name'].errors &&
                            pursuitForm.controls['name'].errors['maxlength']">
                    Pursuit name must be 80 characters or less.
                </div>
            </div>
            <!--phase dropdown goes here-->
            <select-field *ngIf="!pipelineObject._id"
                          [form]="pursuitForm"
                          field="type"
                          label="Deal type"
                          [options]="types"
                          [required]="true"
                          [cssClass]="'edit-form--select-popover'"
                          (onChange)="chooseDealType($event)"></select-field>
            <div *ngIf="pipelineObject._id">
                <ion-label stacked>Deal type</ion-label>
                <p>{{dealType}}</p>
            </div>

            <div data-automation="client-company-search">
                <ion-label stacked>
                    Client Company
                    <add-record
                            linkText="Create new"
                            objectName="Company"
                            (onRecordCreated)="setQuickAddRecord($event, companyAutoComplete, 'clientCompany', 'clientCompanyId')">
                    </add-record>
                </ion-label>
                <ion-auto-complete #company
                                [dataProvider]="companiesService"
                                [options]="{ placeholder : 'Find company' }"></ion-auto-complete>
            </div>

            <div data-automation="client-contact-search">
                <ion-label stacked>
                    Client Contact
                    <add-record
                            linkText="Create new"
                            objectName="Contact"
                            (onRecordCreated)="setQuickAddRecord($event, contactAutoComplete, 'clientContact', 'clientContactId')">
                    </add-record>
                </ion-label>
                <ion-auto-complete #contact
                                [dataProvider]="contactsService"
                                [options]="{ placeholder : 'Find contact' }"></ion-auto-complete>
            </div>

            <div
                data-automation="property-search"
                [hidden]="!dealType || isBuyerRep || isTenantRep">
                <ion-label stacked>
                    Property
                    <add-record
                            linkText="Create new"
                            objectName="Property"
                            (onRecordCreated)="setQuickAddRecord($event, propertyAutoComplete, 'tempProperty', 'tempPropertyId')">
                    </add-record>
                </ion-label>
                <ion-auto-complete
                    #property
                    (changeValue)="propertyChanged($event)"
                    (itemSelected)="propertySelected($event)"
                    [dataProvider]="propertiesService"
                    [options]="{ placeholder : 'Find property' }"></ion-auto-complete>
            </div>

            <div
                [hidden]="!spacesEnabled || !isLandlordRep"
                data-automation="space-search">
                <ion-label stacked>Space</ion-label>
                <ion-auto-complete
                    #space
                    [dataProvider]="spaceAutocompleteService"
                    [options]="{ placeholder : 'Find space' }"></ion-auto-complete>
            </div>

        </ion-card-content>
    </ion-card>
    <ion-card *ngIf="dealType" style="overflow: unset;">
        <ion-card-header>Additional information</ion-card-header>
        <ion-card-content>
            <ion-item>
                <ion-label stacked>Commission amount</ion-label>
                <ion-input formControlName="commissionAmount"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="pursuitForm.get('commissionAmount').dirty &&
                    pursuitForm.get('commissionAmount').errors &&
                    pursuitForm.get('commissionAmount').errors.pattern">
                {{validationMessages.DECIMAL_REGEX}}
            </div>
            <ion-item>
                <ion-label stacked>Probability (%)</ion-label>
                <ion-input formControlName="probability"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="pursuitForm.get('probability').dirty &&
                    pursuitForm.get('probability').errors &&
                    pursuitForm.get('probability').errors.pattern">
                {{validationMessages.PERCENT_INT_REGEX}}
            </div>
            <ion-item *ngIf="isSellerRep">
                <ion-label stacked>Seller price expectation</ion-label>
                <ion-input formControlName="sellerPriceExpectation"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="pursuitForm.get('sellerPriceExpectation').dirty &&
                    pursuitForm.get('sellerPriceExpectation').errors &&
                    pursuitForm.get('sellerPriceExpectation').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <ion-item *ngIf="isSellerRep">
                <ion-label stacked>Broker proposed price</ion-label>
                <ion-input formControlName="brokerProposedPrice"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="pursuitForm.get('brokerProposedPrice').dirty &&
                    pursuitForm.get('brokerProposedPrice').errors &&
                    pursuitForm.get('brokerProposedPrice').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <div *ngIf="isLandlordRep">
                <date-field [defaultDate]="pursuitForm.controls.pursuitDeliveryDate.value"
                            [form]="pursuitForm"
                            field="pursuitDeliveryDate"
                            label="Pursuit delivery date"
                            [required]="false"
                            [options]="datepickerOptions"></date-field>
            </div>
            <div class="field-error"
                 *ngIf="pursuitForm.controls['probability'].dirty &&
              pursuitForm.controls['probability'].errors &&
              (pursuitForm.controls['probability'].errors['min'] ||
              pursuitForm.controls['probability'].errors['max'])">
                This is a percentage field (0-100)
            </div>
        </ion-card-content>
    </ion-card>

    <photo-scrollview *ngIf="photos.length > 0"
                      [photoUris]="photos"></photo-scrollview>

    <busy-panel *ngIf="isSaving"
                message="Saving pursuit..."></busy-panel>
</form>
