<ion-content class="page list-page tasks-page">
    <ion-grid class="content-grid">
        <ion-row class="list-page--header">
            <h2>Tasks</h2>

            <div class="list-page--summary">
                <apto-icon icon="task" circle inline size="2"></apto-icon>
                <div class="list-page--label">
                    <div class="list-page--line">
                        <strong *ngIf="!isInitializing; else breakdownShimmer">
                            You currently have
                            <span *ngIf="overdueTasks > 0">
                                <span class="tasks-page--overdue-tasks-label">{{overdueTasks}} overdue tasks</span> of
                            </span>
                            <span class="tasks-page--open-tasks-label">{{openTasks}} open tasks</span>.
                        </strong>
                        <ng-template #breakdownShimmer>
                            <apto-shimmer class="tasks-page--breakdown-shimmer"></apto-shimmer>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ion-row>
        <ion-row class="list-page--body tasks-page--body">
            <ion-col class="list-page--filters tasks-page--body-left"
                     col-auto>
                <ion-content>
                    <ion-card class="search-and-filter">
                        <ion-card-header>
                            Search and filter tasks
                        </ion-card-header>
                        <ion-card-content>
                            <filter-panel #searchFilterPanel></filter-panel>
                            <div class="filter-panel--component">
                                <div class="filter-panel--children">
                                    <span class="filter-panel--title">Filter by</span>
                                    <filter-panel #tasksFilterPanel></filter-panel>
                                </div>
                                <div *ngIf="!filtersAreEmpty()"
                                     class="filter-panel--reset-container">
                                    <button class="filter-panel--reset-button"
                                            ion-button
                                            clear
                                            color="primary-blue"
                                            (click)="resetFilters()">
                                        Reset Filters
                                    </button>
                                </div>
                            </div>
                        </ion-card-content>
                    </ion-card>
                </ion-content>
            </ion-col>
            <ion-col class="tasks-page--body-right">
                <ion-content>
                    <ion-card *ngIf="!isInitializing">
                        <ion-card-header>
                            <ion-buttons start>
                                <button ion-button
                                        clear
                                        [ngClass]="{ active: listType === 'open' }"
                                        (click)="setListType('open')"
                                        [disabled]="listType === 'open'">
                                    Open Tasks
                                </button>
                                <button ion-button
                                        clear
                                        [ngClass]="{ active: listType === 'completed' }"
                                        (click)="setListType('completed')"
                                        [disabled]="listType === 'completed'">
                                    Completed Tasks
                                </button>
                            </ion-buttons>
                            <ion-buttons end>
                                <button class="automation--add-button"
                                        ion-button
                                        clear
                                        (click)="addTask()">
                                    ADD TASK
                                </button>
                            </ion-buttons>
                        </ion-card-header>

                        <ion-card-content>
                            <ion-item class="sort-by-select">
                                <span>Sort By</span>
                                <apto-ionic-select [options]="sortKeys"
                                                   [value]="selectedSortKey"
                                                   (onSelectOption)="sortChanged($event.value)"></apto-ionic-select>
                            </ion-item>

                            <ion-list>
                                <task-item *ngFor="let task of tasks"
                                           [task]="task"
                                           [showSnooze]="true"
                                           (onSnoozeTask)="snoozeTask(task)"
                                           (onToggleTask)="toggleTask(task)"
                                           (onClick)="viewTask(task)"></task-item>

                                <ion-item *ngIf="tasks.length === LIST_VIEW_MAX_RECORDS"
                                           
                                          class="list-page--additional-items-label">
                                    Search/filter to view additonal Tasks
                                </ion-item>
                                <ion-item *ngIf="listType === 'open' && tasks.length === 0">
                                    There are no open tasks to display.
                                </ion-item>
                                <ion-item *ngIf="listType === 'completed' && tasks.length === 0">
                                    There are no completed tasks to display.
                                </ion-item>
                            </ion-list>
                        </ion-card-content>
                    </ion-card>

                    <busy-panel *ngIf="isInitializing"
                                message="Loading tasks..."></busy-panel>
                </ion-content>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
