<ion-content class="page list-page companies-page">
    <ion-grid class="content-grid">
        <ion-row class="list-page--header">
            <h2>Companies</h2>
            <div class="list-page--summary">
                <apto-icon icon="company" circle inline size="2"></apto-icon>
                <div class="list-page--label">
                    <div class="list-page--line">
                        <span *ngIf="!isInitializing; else totalShimmer">
                            <strong>{{ items.length }}</strong> of {{ totalCompanies }}</span>
                        <ng-template #totalShimmer>
                            <apto-shimmer class="companies-page--total-shimmer"></apto-shimmer>
                        </ng-template>
                    </div>
                    <div class="list-page--line list-page--small">
                        Companies
                    </div>
                </div>
            </div>

            <div class="list-page--summary">
                <apto-icon icon="trendingUp" circle inline size="2"></apto-icon>
                <div class="list-page--label">
                    <div class="list-page--line">
                        <strong *ngIf="!isInitializing; else breakdownShimmer">{{breakdown}}</strong>
                        <ng-template #breakdownShimmer>
                            <apto-shimmer class="companies-page--breakdown-shimmer"></apto-shimmer>
                        </ng-template>
                    </div>
                    <div class="list-page--line list-page--small">
                        Breakdown of top company types
                    </div>
                </div>
            </div>
        </ion-row>
        <ion-row class="list-page--body">
            <ion-col class="list-page--filters companies-page--filters"
                     col-auto>
                <ion-content>
                    <add-call-list-members-from-companies-card
                        *ngIf="leasingEnabled && selectedItemsArray.length > 0"
                        [companies]="selectedItemsArray"
                        (onClearAll)="clearAllItems()">
                    </add-call-list-members-from-companies-card>
                    <ion-card class="search-and-filter">
                        <ion-card-header>
                            Search and filter companies
                        </ion-card-header>
                        <ion-card-content>
                            <filter-panel
                                #searchFilterPanel
                                data-automation="search-filter-panel">
                            </filter-panel>
                            <div class="filter-panel--component">
                                <div class="filter-panel--children">
                                    <span class="filter-panel--title">Filter by</span>
                                    <filter-panel
                                        #companiesFilterPanel
                                        data-automation="company-filter-panel">
                                    </filter-panel>
                                    <filter-panel
                                        *ngIf="leasingEnabled"
                                        #leaseFilterPanel
                                        data-automation="company-lease-filter-panel">
                                    </filter-panel>
                                </div>
                                <div *ngIf="!filtersAreEmpty()"
                                     class="filter-panel--reset-container">
                                    <button class="filter-panel--reset-button"
                                            ion-button
                                            clear
                                            color="primary-blue"
                                            (click)="resetFilters()">
                                        Reset Filters
                                    </button>
                                </div>
                            </div>
                        </ion-card-content>
                    </ion-card>
                </ion-content>
            </ion-col>
            <ion-col>
                <ion-content>
                    <ion-card *ngIf="!isInitializing">
                        <ion-card-header>
                            <ion-buttons start>
                                <button ion-button
                                        clear
                                        [ngClass]="{ active: listType === 'recent' }"
                                        (click)="setListType('recent')"
                                        [disabled]="listType === 'recent'">
                                    Recent Companies
                                </button>
                                <button ion-button
                                        clear
                                        [ngClass]="{ active: listType === 'all' }"
                                        (click)="setListType('all')"
                                        [disabled]="listType === 'all'">
                                    All Companies
                                </button>
                            </ion-buttons>

                            <ion-buttons end>
                                <button class="automation--add-button"
                                        ion-button
                                        clear
                                        (click)="addCompany()">
                                    ADD COMPANY
                                </button>
                            </ion-buttons>
                        </ion-card-header>
                        <ion-card-content>
                            <div class="list-view--info-row">
                                <ion-checkbox
                                    *ngIf="leasingEnabled"
                                    aria-label="select all"
                                    class="small select-all"
                                    mode="ios"
                                    color="primary-blue"
                                    [ngModel]="currentPageSelected"
                                    (ngModelChange)="selectAllChanged($event)">
                                </ion-checkbox>
                                <div class="list-page--line">
                                    <ion-label *ngIf="!isInitializing">
                                        Showing {{items.length}} of {{totalCompanies}} companies
                                    </ion-label>
                                </div>
                                <ion-item *ngIf="listType !== 'recent'" class="sort-by-select">
                                    <span>Sort By</span>
                                    <apto-ionic-select
                                        [options]="sortKeys"
                                        [value]="selectedSortKey"
                                        (onSelectOption)="sortChanged($event.value)">
                                    </apto-ionic-select>
                                </ion-item>
                            </div>
                            <ion-list>
                                <span *ngIf="leasingEnabled">
                                    <company-item
                                        *ngFor="let company of items"
                                        selectable="true"
                                        [selected]="selectedItems.hasOwnProperty(company._id)"
                                        [company]="company"
                                        (onSelectToggled)="selectChanged($event, company)"
                                        (onClick)="viewCompany($event)"></company-item>
                                </span>
                                <span *ngIf="!leasingEnabled">
                                    <company-item
                                        *ngFor="let company of items"
                                        [company]="company"
                                        (onClick)="viewCompany($event)"></company-item>
                                </span>
                                <ion-item *ngIf="items.length === LIST_VIEW_MAX_RECORDS"
                                          class="list-page--additional-items-label">
                                    Search/filter to view additonal Companies.
                                </ion-item>
                                <ion-item *ngIf="items.length === 0">
                                    There are no companies to display.
                                </ion-item>
                            </ion-list>
                        </ion-card-content>
                    </ion-card>
                    <busy-panel *ngIf="isInitializing"
                                message="Loading Companies..."></busy-panel>
                </ion-content>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
